@import "variable.scss";
.dropdown-menu {
  & > div {
    padding: 0px !important;

    ul {
      padding: 0px !important;
    }
  }
}

.dropdown-menu-item {
  transition: all;
  transition-duration: 0.5s;
  &:hover {
    border-color: $royalBlue;
  }

  &:active {
    background-color: $pressedMenuItem;
    color: $royalBlue;
  }
}
