@import "variable.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
*   Global Form Elements
*/
input[type='radio'] {
  border: solid 2px $royalBlue;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.current-speaker {
  box-shadow: inset 0px 0px 0px 10px $royalBlue;
}

// todo move this as a part of Meet.tsx
.lead-qar {
  border: solid #e64444 3px;
}

// todo move this as a part of Meet.tsx
.lead-lar {
  border: solid #ece93f 3px;
}

.selectField {
  select {
    background-color: #fff;
  }
}
.tableDropdown {
  .w-fit {
    margin-left: auto;
  }
}
