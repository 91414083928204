$royalBlue: #004cb5;
$successGreen: #4cc800;
$pressedMenuItem: #e4f3fe;

/*
*   Table settings
*/
$tableHeadBackground: #f7f8f8;
$tableBorder: #e6e8eb;
$hoverColor: #e4f3fe;
